<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="车辆选择">
                <a-tree-select
                  v-model="deviceIds"
                  :replaceFields="replaceFields"
                  show-search
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="模糊搜索，可搜索自编号或车牌号"
                  allow-clear
                  tree-default-expand-all
                  :tree-data="vehicleTreeData"
                  tree-checkable
                  :show-checked-strategy="SHOW_PARENT"
                  treeNodeFilterProp="title"
                  :maxTagCount="2"
                  class="alarm-search"
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="报警类型">
                <a-select default-value="" v-model="queryParam.warnType">
                  <a-select-option value=""> 全部 </a-select-option>
                  <a-select-option :value="item.value" v-for="item in warnTypeOptions" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="报警时间段">
                <a-range-picker
                  v-model="triggerTimeRange"
                  :show-time="{ format: 'HH:mm:ss' }"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  format="YYYY-MM-DD HH:mm:ss"
                  :placeholder="['开始时间', '结束时间']"
                  @change="onChange"
                  @ok="onOk"
                />
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <!-- <a-button type="dashed" shape="circle" :loading="loading" :style="{ float: 'right' }" icon="reload" @click="getList" /> -->
        </div>
      </a-toolbar>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a @click="handleTrack(record)" v-if="record.triggedTime"> <a-icon type="info-circle" />查看历史轨迹 </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <vxe-modal
      v-model="open"
      title="查看历史轨迹"
      width="70%"
      height="90%"
      @close="open = false"
      show-zoom
      resize
      :lock-view="false"
      :mask="false"
      :position="{ top: '5%', left: '20%' }"
    >
      <track-page :showPlayer="true" :showTrackInfo="true" :params="trackParams" v-if="open" />
    </vxe-modal>
  </page-header-wrapper>
</template>

<script>
import debounce from 'lodash/debounce'
import { listWarningInfo, listWarningInfoArea } from '@/api/jt808/warningInfo'
import { TreeSelect } from 'ant-design-vue'
import { deviceTree } from '@/api/iot/device'
import moment from 'moment'
import TrackPage from '@/views/monitor/monitorComponents/trackpage'
import { queryHistoryCoordinates } from '@/api/jt808/deviceLocation'
export default {
  name: 'AreaManager',
  components: {
    ATreeSelect: TreeSelect,
    TrackPage
  },
  data() {
    this.handleVehicleNoSearch = debounce(this.handleVehicleNoSearch, 500)
    return {
      open: false,
      trackParams: {},
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        warnType: null,
        deviceIds: null,
        pageNum: 1,
        pageSize: 10
      },
      deviceIds: [],
      warnTypeOptions: [
        {
          label: '电子围栏',
          value: 1
        },
        {
          label: '超速报警',
          value: 2
        },
        {
          label: '禁入区域',
          value: 3
        },
        {
          label: '停车超时',
          value: 4
        }
      ],
      columns: [
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车牌自号',
          dataIndex: 'vehicleNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车牌号',
          dataIndex: 'plateNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆功能',
          dataIndex: 'vehicleFunction',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '报警开始时间',
          dataIndex: 'triggedTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '报警结束时间',
          dataIndex: 'clearTime',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '报警类型',
          dataIndex: 'warnType',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            const obj = this.warnTypeOptions.find((p) => p.value === record.warnType)
            return obj ? obj.label : ''
          }
        },

        {
          title: '当时速度(km/h)',
          dataIndex: 'speed',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return Number(record.speed) / 10
          }
        },
        {
          title: '报警开始时位置',
          dataIndex: 'startWarnAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '报警时长',
          dataIndex: 'warnTimeSum',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            const time = moment.duration(record.warnTimeSum, 'seconds')
            const hours = time.hours()
            const minutes = time.minutes()
            const seconds = time.seconds()
            return moment({ h: hours, m: minutes, s: seconds }).format('HH:mm:ss')
          }
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      // 围栏类型
      warnType: 0,
      vehicleTreeData: [],
      triggerTimeRange: [
        this.parseDateTime(new Date(), 'yyyy-MM-dd') + ' 00:00:00',
        this.parseDateTime(new Date(), 'yyyy-MM-dd') + ' 23:59:59'
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.handleVehicleNoSearch()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询sim卡管理列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (this.deviceIds) {
        this.queryParam.deviceIds = this.deviceIds.map((p) => (p.indexOf('t-1-') >= 0 ? p.substr(4) : p))
      }
      if (this.triggerTimeRange !== null && this.triggerTimeRange.length === 2) {
        this.queryParam.params['beginTriggedTime'] = this.triggerTimeRange[0]
        this.queryParam.params['endTriggedTime'] = this.triggerTimeRange[1]
      }
      listWarningInfo(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        name: undefined,
        deptId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleVehicleNoSearch(value) {
      console.log('handleVehicleNoSearch', value)
      const formData = new FormData()
      formData.append('deviceType', 1)
      formData.append('bindStatus', 'bind')
      if (value && !value.trim()) {
        formData.append('bindKey', value.trim())
      }
      deviceTree(formData).then((response) => {
        this.vehicleTreeData = response.data
      })
    },
    handleVehicleNoChange(value) {
      console.log('value', value)
    },
    // 获取围栏数据
    getFenceData(id) {
      console.log('getFenceData')
      const params = {
        warningInfoId: id
      }
      listWarningInfoArea(params).then((res) => {
        this.fenceData = res.data
      })
    },
    async handleTrack(record) {
      console.log('handleTrack')
      const warnType = record.warnType
      const params = {
        warningInfoId: record.id
      }
      let fenceData = []
      await listWarningInfoArea(params).then((res) => {
        fenceData = res.data
      })

      const queryParam = {}
      queryParam.startTime = record.triggedTime
      queryParam.endTime = record.clearTime ? record.clearTime : this.parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss')
      queryParam.deviceId = record.deviceId
      queryHistoryCoordinates(queryParam).then((response) => {
        const data = response.data
        if (data.locations.length === 0) {
          this.$warningEx('查询不到历史轨迹数据！')
          return
        }
        this.trackParams = {
          trackData: data.locations.map((p) => {
            return { ...p, speed: Number(p.speed) / 10, lat: p.coordinates[1], lng: p.coordinates[0] }
          }),
          objectInfo: { ...record, ...data },
          moduleKey: 'traditionalVehicle',
          fenceData: fenceData,
          warnType: warnType
        }
        this.open = true
      })
    }
  }
}
</script>

<style lang="less">
.alarm-search {
  .ant-select-selection--multiple {
    max-height: 60px !important;
  }
}
</style>
