var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 6, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "车辆选择" } },
                            [
                              _c("a-tree-select", {
                                staticClass: "alarm-search",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  replaceFields: _vm.replaceFields,
                                  "show-search": "",
                                  "dropdown-style": {
                                    maxHeight: "400px",
                                    overflow: "auto",
                                  },
                                  placeholder: "模糊搜索，可搜索自编号或车牌号",
                                  "allow-clear": "",
                                  "tree-default-expand-all": "",
                                  "tree-data": _vm.vehicleTreeData,
                                  "tree-checkable": "",
                                  "show-checked-strategy": _vm.SHOW_PARENT,
                                  treeNodeFilterProp: "title",
                                  maxTagCount: 2,
                                },
                                model: {
                                  value: _vm.deviceIds,
                                  callback: function ($$v) {
                                    _vm.deviceIds = $$v
                                  },
                                  expression: "deviceIds",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 6, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "报警类型" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: { "default-value": "" },
                                  model: {
                                    value: _vm.queryParam.warnType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParam, "warnType", $$v)
                                    },
                                    expression: "queryParam.warnType",
                                  },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "" } },
                                    [_vm._v(" 全部 ")]
                                  ),
                                  _vm._l(_vm.warnTypeOptions, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.value,
                                        attrs: { value: item.value },
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "报警时间段" } },
                            [
                              _c("a-range-picker", {
                                attrs: {
                                  "show-time": { format: "HH:mm:ss" },
                                  "value-format": "YYYY-MM-DD HH:mm:ss",
                                  format: "YYYY-MM-DD HH:mm:ss",
                                  placeholder: ["开始时间", "结束时间"],
                                },
                                on: { change: _vm.onChange, ok: _vm.onOk },
                                model: {
                                  value: _vm.triggerTimeRange,
                                  callback: function ($$v) {
                                    _vm.triggerTimeRange = $$v
                                  },
                                  expression: "triggerTimeRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("a-col", { attrs: { md: 4, sm: 24 } }, [
                        _c(
                          "span",
                          {
                            staticClass: "table-page-search-submitButtons",
                            style:
                              (_vm.advanced && {
                                float: "right",
                                overflow: "hidden",
                              }) ||
                              {},
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.handleQuery },
                              },
                              [
                                _c("a-icon", { attrs: { type: "search" } }),
                                _vm._v("查询"),
                              ],
                              1
                            ),
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "8px" },
                                on: { click: _vm.resetQuery },
                              },
                              [
                                _c("a-icon", { attrs: { type: "redo" } }),
                                _vm._v("重置"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-toolbar",
            {
              attrs: { loading: _vm.loading, refresh: "", tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [_c("div", { staticClass: "table-operations" })]
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              size: _vm.tableSize,
              rowKey: "id",
              tid: "1",
              columns: _vm.columns,
              "data-source": _vm.list,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "operation",
                fn: function (text, record) {
                  return _c("span", {}, [
                    record.triggedTime
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleTrack(record)
                              },
                            },
                          },
                          [
                            _c("a-icon", { attrs: { type: "info-circle" } }),
                            _vm._v("查看历史轨迹 "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
      _c(
        "vxe-modal",
        {
          attrs: {
            title: "查看历史轨迹",
            width: "70%",
            height: "90%",
            "show-zoom": "",
            resize: "",
            "lock-view": false,
            mask: false,
            position: { top: "5%", left: "20%" },
          },
          on: {
            close: function ($event) {
              _vm.open = false
            },
          },
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _vm.open
            ? _c("track-page", {
                attrs: {
                  showPlayer: true,
                  showTrackInfo: true,
                  params: _vm.trackParams,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }