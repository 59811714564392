import request from '@/utils/request'
const baseURL = '/jt-api'

// 查询报警规则列表
export function listWarningInfo(query) {
  return request({
    url: '/jt808/warningInfo/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 查询报警信息与报警规则围栏关系列表
export function listWarningInfoArea(query) {
  return request({
    url: '/jt808/warningInfo/listWarningInfoArea',
    method: 'get',
    baseURL,
    params: query
  })
}
